<!--
 * @Author: lbh
 * @Date: 2022-09-09 11:49:06
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-17 09:42:21
 * @Description: 頁面列表
-->
<template>
  <div class="page-picker-box">
    <el-input
      v-if="checked"
      v-model="value"
      @input="change"
      clearable
      placeholder="請輸入跳轉鏈接"
    />
    <el-select
      v-else
      v-model="value"
      placeholder="請選擇頁面"
      filterable
      clearable
      @change="change"
      popper-class="pageSelect"
    >
      <el-option
        label="彈窗-免費試用"
        value="helpMe"
      ></el-option>
      <el-option
        label="預約示範-向下滾動"
        value="contactGo"
      ></el-option>
      <el-option
        label="全屏廣告"
        value="fullScreenAd"
      ></el-option>
      <el-option
        v-for="item in pages"
        :key="item.id"
        :label="item.pageName"
        :value="item.pageName"
      >
      </el-option>
    </el-select>
    <el-tooltip
      class="item"
      effect="dark"
      :content="checked ? '頁面' : '自定義'"
      placement="bottom"
    >

      <el-checkbox
        style="margin-left:10px;"
        v-model="checked"
      ></el-checkbox>
    </el-tooltip>

  </div>
</template>

<script>

export default {
  name: "self-page-picker",
  props: {
    modelValue: {
      default () {
        return ""
      }
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  watch: {
    modelValue (n) {
      console.log('新書黃家駒',n);
      if (n && n != this.value) {
        if (n.includes('http')) this.checked = true;
        this.value = n;
      }
    }
  },
  data () {
    return {
      pages: [],
      value: '',
      checked: false
    }
  },
  created () {
    let pages = this.$store.getters.getPages;
    this.pages = pages;
    this.value = this.modelValue;
  },
  methods: {
    change (e) {
      this.$emit('change', e)
      this.$emit('onChange', e)
    }
  }
}
</script>

<style lang="less" scoped>
.page-picker-box {
  display: flex;
  align-items: center;
}
</style>